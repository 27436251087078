<template>
    <page title="请假列表" class="approve-list-page" :hasBack="false" textAlign="center">
        <nav-page :roleType="2">
            <form action="/" class="search">
                <van-search @search="onSearch"  @cancel="onSearch" name="keyword" v-model="searchValue"  placeholder="请输入搜索吧..." />
            </form>
            <van-tabs
                v-model:active="currentTabType"
                title-active-color="#07C160"
                title-inactive-color="#666666"
                color="#07C160"
                @change="tabChange"
            >
                <van-tab v-for="item in tabList" :key="item.type" :title="item.title" :name="item.type">
                    <van-pull-refresh v-model="item.refreshing" @refresh="onRefresh">
                        <van-list
                            @load="onLoad"
                            v-model:loading="item.loading"
                            :finished="item.finished"
                            finished-text="已经到底了"
                            :offset="50"
                            :immediate-check="false"
                            v-if="!item.isLoad || item.leaveList.length"
                        >
                            <div class="approve-item"   v-for="leave in item.leaveList" @click="viewDetail(leave.id)" :key="leave.id">
                                <div class="avatar-wrapper">
                                    <van-image v-if="leave.photo" class="vant-img" fit="cover" :src="leave.photo" />
                                    <img class="default-img" v-else :src="require('@/assets/images/user/default_avatar.png')" alt="" />
            <!--                            <img class="avatar" :src="item.photo" alt="" />-->
                                </div>
                                <div class="detail">
                                    <div class="des-item">
                                        <span class="name">{{ leave.studentName }}</span>
                                        <span class="class">{{ leave.className }}</span>
                                    </div>
                                    <div class="des-item">
                                        <span class="label">开始时间:</span>
                                        <span class="des">{{ leave.startTime }}</span>
                                    </div>
                                    <div class="des-item">
                                        <span class="label">结束时间:</span>
                                        <span class="des">{{ leave.endTime }}</span>
                                    </div>
                                    <div class="des-item reason">
                                        <span class="label">请假事由:</span>
                                        <span class="des">{{ leave.reason }}</span>
                                    </div>
                                </div>
                                <triangle :type="leave.status" />
                            </div>
                        </van-list>
                        <div v-else class="empty-wrapper">
                            <empty />
                        </div>
                    </van-pull-refresh>
                </van-tab>
            </van-tabs>
        </nav-page>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import Empty from '@/components/empty/Empty';
import NavPage from '@/components/navPage/NavPage';
import {Search, List, Image as VanImage, Tabs, Tab, PullRefresh} from 'vant';
import {reactive, ref} from 'vue';
import commonRequest from '@/services';
import { useRouter } from 'vue-router';
import Triangle from '@/components/triangle/Triangle';

export default {
    name: 'ApproveList',
    components: {
        [Page.name]: Page,
        [Search.name]: Search,
        [List.name]: List,
        [Empty.name]: Empty,
        [NavPage.name]: NavPage,
        [VanImage.name]: VanImage,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [PullRefresh.name]: PullRefresh,
        [Triangle.name]: Triangle,
    },
    setup() {
        const router = useRouter();
        const searchValue = ref('');

        const tabList = reactive([
            {
                title: '全部',
                type: 0,
                loading: false,
                isLoad: false,
                refreshing: false,
                finished: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '待批假',
                type: 1,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已批假',
                type: 2,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已完成',
                type: 3,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已驳回',
                type: 4,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已取消',
                type: 5,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
        ]);
        const currentTabType = ref(1);
        const initPageSize = 10;
        const fetchData = () => {
            const activeIndex = tabList.findIndex((item) => item.type === currentTabType.value);

            tabList[activeIndex].pageNum++;
            tabList[activeIndex].loading = true;
            commonRequest({
                action: 12002,
                status: activeIndex,
                pageNum: tabList[activeIndex].pageNum,
                pageSize: initPageSize,
                keyword: searchValue.value,
            }).then((res) => {
                tabList[activeIndex].loading = false;
                tabList[activeIndex].isLoad = true;
                tabList[activeIndex].refreshing = false;
                if (res.rows) {
                    tabList[activeIndex].leaveList = tabList[activeIndex].leaveList.concat(res.rows);
                    if (res.total <= tabList[activeIndex].leaveList.length) {
                        tabList[activeIndex].finished = true;
                    }
                }
            });
        };
        const onRefresh = () => {
            const activeIndex = tabList.findIndex((item) => item.type === currentTabType.value);
            tabList[activeIndex].leaveList = [];
            tabList[activeIndex].pageNum = 0;
            tabList[activeIndex].refreshing = true;
            tabList[activeIndex].finished = false;
            tabList[activeIndex].isLoad = false;
            fetchData();
        };
        const onLoad = () => {
            fetchData();
        };
        const tabChange = () => {
            const activeIndex = tabList.findIndex((item) => item.type === currentTabType.value);
            console.log(tabList[activeIndex].isLoad, activeIndex, 'tabChange');
            if (!tabList[activeIndex].isLoad) {
                fetchData();
            }
        };
        fetchData();

        const viewDetail = (id) => {
            router.push({
                path: '/approveDetail',
                query: {id}
            })
        };

        const onSearch = () => {
            for (let i = 0; i < tabList.length; i++) {
                tabList[i].leaveList = [];
                tabList[i].pageNum = 0;
                tabList[i].refreshing = true;
                tabList[i].finished = false;
                tabList[i].isLoad = false;
            }
            onLoad();
        };

        return {
            tabList,
            currentTabType,
            onRefresh,
            onLoad,
            tabChange,
            viewDetail,
            onSearch,
            searchValue
        };
    },
};
</script>
<style lang="less" scoped>
.approve-list-page {
    padding-bottom: 20px;
    box-sizing: border-box;
    /deep/ .nav-page {
        display: flex;
        flex-direction: column;
    }
    /deep/ .van-list{
        flex: 1;
    }
    /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
        padding-left: 0;
    }
}
.search {
    flex-shrink: 0;
}
.approve-item {
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 5px;
    height: 151px;
    margin-bottom: 10px;
    position: relative;
}
.avatar-wrapper {
    height: 111px;
    background: #e2e2e2;
    border-radius: 5px;
    position: relative;
    width: 75px;
    margin-right: 10px;
    .vant-img {
        width: 100%;
        height: 100%;
    }
    .default-img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
}
.empty-wrapper {
    height: 350px;
}
.detail {
    flex: 1;
    .des-item {
        margin-bottom: 10px;
    }
    .name,
    .class {
        font-size: 15px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 17px;
        color: #333333;
    }
    .name {
        margin-right: 10px;
    }
    .label {
        font-size: 14px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
        margin-right: 5px;
    }
    .des {
        font-size: 14px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #333333;
    }
    .reason {
        display: flex;
        padding-right: 15px;
        .label {
            flex-shrink: 0;
        }
        .des {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
</style>
